a{
  text-decoration: none;
  color: white;
  cursor: pointer;
}


.cards {
    position: relative;
    text-align: center;
    display:flex; justify-content: center;
    flex-wrap: wrap;
    margin-top: 80px;
   }

.card { 
    cursor: pointer;
    border-radius: 0.90rem;
    margin: 20px;
    margin-top: 40px;
    box-shadow: 0 20px 40px -14px #043055;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 240px;
    height: 260px;
    align-items: center;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
  }


  .subject{
    width: 100%;
    text-align: center;
    font-size: 30px;
    font-weight:700;
    color: white;
    cursor: pointer;
    background-color: #043055;

  }

  .ishim{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/אישים.png");
    
  }

  .omanut{
    width: 120%;
    height: 300px;
    background-position:bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/אמנות.jpeg");
  }

  .bikorot{
    width: 100%;
    height: 240px;
    background-position:bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/ביקורות - ביקורת על עגנון.jpg");
  }

  .bikoretAlKtavav{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/ביקורת על כתביו.png");
  }

  .historia{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/היסטוריה - פסיפס.jpg");
  }

  .hespedim{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/הספדים - נר נשמה.webp");
  }

  .chevra{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/חברה - חייל וחרדי.jpg");
  }

  .chinuch{
    width: 100%;
    height: 225px;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/חינוך - מורי מלמד.jpg");
  }

  .yahadut{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/יהדות - הכותל.jpeg");
  }

  .yiddish{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/יידיש - שטעטל.jpg");
  }

  .mediniyut{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/מדיניות - גלובוס.jpg");
  }

  .michtavim{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/מכתבים.jpg");
  }

  .sifrut{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/ספרות - ספרים.jpeg");
  }

  .avodaSotzialit{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/עבודה סוציאלית.jpeg");
  }

  .filosofia{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/פילוסופיה - הרמבם.jpg");
  }

  .tziyonut{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/ציונות - הרצל.jpg");
  }

  .tziyonutDatit{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/ציונות דתית - בית כנסת ציוני.jpg");
  }

  .rishmeyMasa{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/רשמי מסע - קופנהאגן.jpg");
  }

  .nispachim{
    width: 100%;
    height: 215px;
    background-position:center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(to top, #214369 0%, rgba(4, 48, 85, 0) 10%),
    url("../images-folder/נספחים - מהדק.webp");
  }
