.relative {
    position: relative;
}

#cover {
    width: 100%; 
    height: 600px;
    object-fit: cover;
}

.homepageTitle{
    position:absolute;
    top: 150px;
    right: 50px;
    font-size: 50px;
    color: white;
    z-index: 1;
}

.subtitle{
    font-size: 35px;
    width: 60%; 
}

.svg{
    height: 60px; 
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(178deg) brightness(106%) contrast(101%)
}

.statistics{
    position: absolute;
    left: 40px;
    top: 200px;
    color: white;
    font-size: 30px;
    line-height: 95px;
}

/* not Mobile Mode, just style */

@media screen and (max-width: 940px) {

    .statistics{
        position: absolute;
        left: 20px;
        top: 390px;
        color: white;
        font-size: 30px;
        line-height: 95px;
    }
    .statistics div {
        height: 50px;
    }

    .svg{
        height: 30px; 
    }

}

/* Mobile Mode */

@media screen and (max-width: 765px) {

    #cover {
        height: 400px;
    }

    .homepageTitle{
        top: 30px;
        right: 0px;
        text-align: center;
        font-size: 25px;
        letter-spacing: 1px;
        line-height: 2;
    }

    .subtitle{
        letter-spacing: 0px;
        line-height: normal;
        text-align: right;
        font-size: 20px;
        padding-top: 10px;
        width: 80%; 
        padding-right: 10%;
        padding-left: 10%;
    }

    .svg{
        height: 20px; 
     }

     .statistics{
        position: absolute;
        left: 0;
        top: 200px;
        width: 95%;
        color: white;
        font-size: 20px;
        line-height: 95px;
    }
    .statistics div {
        height: 30px;
    }

}

@media screen and (max-width: 457px) {
    .statistics {
        top: 230px;
    }
}

@media screen and (max-width: 361px) {
    .homepageTitle{
        font-size: 20px;
    }
    .statistics {
        top: 260px;
    }
}