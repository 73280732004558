
body {
  margin: 0px;
  background-color: #f4f4f4;
  font-family: Assistant;
  font-size: 16px;
  line-height: 1.5;
  }


.fw200 {
  font-weight: 200;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.title{
  z-index: 2;
  position: absolute;
  font-size: 35px;
  color: #087cac;
  text-align: right;
  margin-top: 25px;
  margin-right: 42px;
} 

.circle{
  position: absolute;
    margin-top: 35px;
    width: 35px;
}

.container {
  position: relative; 
  padding: 100px 40px 40px;
  display: flex;
  height: 100%;
  font-size: x-large;
}

.paddtop50 {
  padding-top: 50px;
}


/* Mobile Mode */

@media screen and (max-width: 765px) {

  .container {
    padding-top: 10px;
    padding-right: 10px;
  }
  .circle{
      margin-top: 10px;
  }
  .title{
      margin-top: 0;
  }  
}




