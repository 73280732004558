  
  /* header */
  
  .header {
    background-color: #043055;
    position:fixed;
    width: 100%;
    display: block;
    z-index: 3;
  }
  
  .header ul {
    margin: 0;
    margin-top: 26px;
    padding: 0;
    list-style: none;
    margin-left: 1%;
    font-size: x-large; 
    height: 70px; 
  }
  
.header li {
  float: left;
}

.header ul li a, ul li a:link, ul li a:visited {
  color: white;
  text-decoration: none;
  padding: 28px 30px;
  position: relative;
}


.underline ul li a::before {
  top: 0px; bottom: 0px; left: 0px; right: 0;
  content: "";
  position: absolute;
  background-image: linear-gradient(90deg, #00aaff9e, #00aaff);
  background-size: 0 9px;
  background-position: bottom;
  background-repeat: no-repeat;
  transition: background-size .3s ease-in;
}

.underline ul li a:hover::before {
  background-size: 80% 9px;
}

  .underlineCurrent {
  content: "";
  background-image: linear-gradient(90deg, #00aaff9e, #00aaff);
  background-size: 80% 9px;
  background-repeat: no-repeat;
  background-position: bottom;
  } 

.column {
    display:flex;
    flex-direction: column;
}
  


#logo {
  height: 70px;
  margin-top: 15px;
  margin-right: 5px;
  float: right;
  display: inline;
}




.titleBar{
  z-index: 2;
  width: 100%;
  right: 0; left: 0;
}

.titleBarText {
  background-color: #385474;
  position: absolute;
  font-size:medium;
  color: white;
  text-align: right;
  width: 80%;
  padding-right: 20%;
  
}



/* mobile mode */

@media screen and (max-width: 765px) {

  .header {
    display: none;
  }
}

  /* not mobile mode, just for style */

@media screen and (max-width: 800px) {

  .header ul li a, ul li a:link, ul li a:visited {
    padding: 28px 25px;
  }
  
}
