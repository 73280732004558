
.container-header {
    padding: 0 40px;
    padding-top: 40px;
}

.stats{
    direction: ltr;
    margin-top: 0.5rem;
    font-style: italic;
    font-size: .8rem;
    color: #087cac;
}


#focusSearchBtn{
    display: inline-block;
    border-radius: 0.25rem;
    border-style: hidden;
    font-size: 1rem;
    font-family: 'assistant';
    color: white;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    margin-right: 40px;
    vertical-align: middle;
    cursor: pointer;
}


.dropdown-arrow {
    margin-left: 0.3em;
    margin-right: 0;
    border-left: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-top: 0.3em solid;
    content: "";
    display: inline-block;
    height: 0;
    vertical-align: middle;
    width: 0;
  }

  .bgBlue {
    background-color: #087cac;
  }

    .bgBlueClicked {
    background-color: #385474;
  }
  


.focusSearchCard {
    margin-top: 10px;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 10px;
    padding: 10px;
    border: 3px solid #087cac;
    background-color: #ffffff;
    border-radius: 5px;
    border-width: 0px;
    box-shadow: 0 0 0 0.1rem rgba(0,127,173,.25);
    columns: 3;
}

.unit{
    break-inside:avoid;
}

.panel-header {
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
    border-bottom: 1px solid #c4c8d8;
    color: #087cac;
    line-height: 1rem;
    width: 60%;
}


.ais-RefinementList-item{
    line-height:1.5;
}
.ais-RefinementList-checkbox{
    margin: 0px;
}





.container-search {
    padding: 0px 40px 40px;
    height: 100%;
}
.results {
    columns: 4; 
    column-gap: 0; 
}


.hitsArticle{
    width: 100%;
    line-height:200%;
}
.hits {
    list-style: none;
    margin: 25px 0;  
}
.hitCategory{
    font-size: larger;
}
.hitName {
    font-size:xx-large;
    font-weight: bold;
    text-align: center;
    padding-bottom: 2%;
}
.hitNewspaper{
    font-size: larger;
}
.hitDates{
    font-size: larger;
    padding-bottom: 2%;
}
.hitTags{
    padding-bottom: 0%;
    line-height:150%;
    font-style: italic;
}

.ais-Hits-item {
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
    break-inside:avoid;
}
.ais-Hits-item:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor:pointer;
}





[class^=ais-] {
    font-size: 1rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}



@media screen and (max-width: 1000px) {
    .results {
        columns: 3;
    }
     } 
@media screen and (max-width: 800px) {
    .results {
        columns: 2;
    }
    }
@media screen and (max-width: 570px) {    
    .focusSearchCard {
        columns: 2;
    }
}
@media screen and (max-width: 500px) {
    .results {
        columns: 1;
    }
}
@media screen and (max-width: 410px) {
    .focusSearchCard {
        columns: 1;
    }
    .panel-header{
        width: 90%;
    }
    .hitName {
        font-size: x-large;
    }
}



.ais-RefinementList-showMore{
    text-indent: -9999px;
}
.ais-RefinementList-showMore::after {
    content: "עוד / צמצם";
    text-indent: 0;
}
.ais-RefinementList-showMore:focus{
    border-color: #087cac;
    box-shadow: #087cac 0 0 0 0.5px, rgba(35, 38, 59, 0.05) 0 2px 0 1px;
}

.ais-SearchBox-input:focus{
    border-color: #d6d6e7;
}