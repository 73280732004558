
/* Basic Styles */


.container1 {
  position: relative;
  width: 100%;
  background-color: #043055;
  line-height: normal;
}

/* Toggle Functionality */

#toggle {
  display: none;
}



#toggle:checked {
  
  & ~ .toggle-container .button-toggle {
    box-shadow: 0 0 0 765px rgba( 0, 0, 0, 0.1), inset 0 0 0 20px rgba( 0, 0, 0, 0.1);

    &:before {
      transform: translateY(-50%) rotate(45deg) scale(1);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg) scale(1);
    }
  }


  & ~ .nav {
    padding-bottom: 45px;
    pointer-events: auto;
    transform: translateY(15%) scaleY(1);


    .nav-item {
        font-size: 20px;
      color: white;
      letter-spacing: 0;
      height: 40px;
      line-height: 40px;
      margin-top: 0;
      opacity: 1;
      transition: 0.5s, opacity 0.1s; 
      transform: scaleY(1);

      &:before {
        opacity: 0;
      }
    }
  }


}

/* Toggle Button */

.button-toggle {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 25px;
  background-color: none;
  cursor: pointer;
  border-radius: 100%;
  transition: 0.5s + 0.1;
  


  &:before, &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    border-radius: 5px;
    transition: 0.5s;
  }

  &:before {
    transform: translateY(-50%) rotate(45deg) scale(0);
  }

  &:after {
    transform: translateY(50%) rotate(-45deg) scale(0);
  }
}

/* Menu */

.nav {
  display: inline-block;
  padding: 25px 25px 20px;
  padding-bottom: 0;
  pointer-events: none;
  transition: 0.5s;
}


.nav-item {
  position: relative;
  float: right;
  clear: both;
  font-size: 14px;
  color: white;
  height: 8px;
  line-height: 7px;
  transform: scaleY(0.2);
  white-space: nowrap;
  transition: 0.5s, opacity 1s;


  &:nth-child(4) {
    opacity: 0;
  }

  &:nth-child(5) {
    opacity: 0;
  }

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    right: 0;
    width: 100%;
    height: 2px;
    background-color: white;
    transform: translateY(-50%) scaleY(5);
    transition: 0.5s;
  }
}


@media screen and (min-width: 766px) {

    .container1 {
      display: none;
    }
  
  }


  #logoMobile{
    height: 50px;
    margin-top: 10px;
    margin-left: 10px;
    float: left;
    display: inline;
  }